'use client';

import Skeleton from '@frontend/jetlend-web-ui/src/ui/Skeleton/Skeleton';
import { buildClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import styles from './MainPageMainSectionSkeleton.module.scss';
import SectionSkeleton from '@app/ui/Section/SectionSkeleton';

export default function MainPageMainSectionSkeleton () {

    const titleClassName = buildClassNames(styles, [
        'title',
        'title--skeleton',
    ]);

    const descriptionClassName = buildClassNames(styles, [
        'main-info__description',
        'main-info__description--skeleton',
    ]);

    return (
        <div className={styles['desktop-view']}>
            <SectionSkeleton>
                <div className={styles['container']}>
                    <div className={styles['main-info']}>
                        <Skeleton className={titleClassName} />
                        <div className={styles['main-info__subsection']}>
                            <Skeleton className={descriptionClassName} />
                            <div className={styles['achievements--skeleton']}>
                                <Skeleton className={styles['achievement']} />
                                <Skeleton className={styles['achievement']} />
                                <Skeleton className={styles['achievement']} />
                                <Skeleton className={styles['achievement']} />
                            </div>
                        </div>
                    </div>
                    <div className={styles['statistics-skeleton']}>
                        <Skeleton className={styles['statistics-skeleton--item']} />
                        <Skeleton className={styles['statistics-skeleton--item']} />
                        <Skeleton className={styles['statistics-skeleton--item']} />
                    </div>
                </div>
            </SectionSkeleton>
        </div>
    );
};
